import {
    CompanyBillingType,
    Gender,
    HouseType,
    JobPosition,
    JobType,
    LegalNature,
    LoanPurpose,
    MaritalStatus,
    ProfessionalStatus,
} from '@/types/Client/options';
import { IdentificationNumber, Measurement } from '@/types/generics';
import { z } from 'zod';

export const combustibleType: Record<string, string> = {
    GASOLINE: 'Gasolina',
    DIESEL: 'Diésel',
    ELECTRIC: 'Eléctrico',
    HYBRID_DIESEL: 'Híbrido (Diésel)',
    HYBRID_GASOLINE: 'Híbrido (Gasolina)',
    PLUG_IN_HYBRID: 'Híbrido enchufable',
    GASOLINE_LPG: 'Gasolina (Gasolina - GLP)',
    GASOLINE_CNG: 'Gasolina (Gasolina - GNC)',
};

export const combustibleTypeEnum: readonly string[] = Object.keys(combustibleType) as (keyof typeof combustibleType)[];
export const CombustibleType = z.enum([combustibleTypeEnum[0], ...combustibleTypeEnum]);

export const GearboxType = z.ZodEnum.create(['MANUAL', 'AUTOMATIC', 'DELETE']);

export type GearboxType = z.infer<typeof GearboxType>;

export const Address = z.object({
    houseType: HouseType.nullable().optional(),
    route: z.string().nullable().optional(),
    streetNumber: z.string().nullable().optional(),
    postalCode: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    province: z.string().nullable().optional(),
    domicile: z.string().nullable().optional(),
    complete: z.string().nullable().optional(),
});

export const Addresses = z.object({
    billingAddress: Address.nullable(),
});

export const BankDetail = z.object({
    iban: z.string().nullable().optional(),
    swift: z.string().nullable().optional(),
    isAccountAtLeast6MonthsOlder: z.string().or(z.boolean()).nullable().optional(),
    isBalanceAtLeast200: z.string().or(z.boolean()).nullable().optional(),
});
export const CoownerDetail = z.object({
    exists: z.boolean(),
    name: z.string().nullable(),
    lastName: z.string().nullable().optional(),
    secondLastName: z.string().nullable().optional(),
    identificationNumber: IdentificationNumber.nullable(),
    email: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
});

export const Employment = z.object({
    jobPosition: JobPosition.nullable(),
    monthlyIncome: z.number().nullable(),
    annualIncome: z.number().nullable(),
    companyName: z.string().nullable(),
    professionalStatus: ProfessionalStatus.nullable(),
    seniorityDate: z.string().datetime().nullable(),
    incomeSource: z.string().nullable(),
    permanentContract: z.string().nullable(),
    jobType: JobType.nullable(),
    companyBillingType: CompanyBillingType.nullable(),
});

export const ContractInfo = z.object({
    warranty: z.boolean().nullable().optional(),
    maintenance: z.boolean().nullable().optional(),
    certifyingAgent: z.boolean().nullable().optional(),
});

export const Vehicle = z.object({
    uuid: z.string().optional(),
    licensePlate: z.string().optional(),
    brand: z.string().nullable().optional(),
    vin: z.string().nullable().optional(),
    model: z.string().nullable().optional(),
    version: z.string().nullable().optional(),
    yearRegistration: z.number().nullable().optional(),
    gearboxType: GearboxType.nullable().optional(),
    combustibleType: CombustibleType.nullable().optional(),
    lastOdometerReadingValue: z.number().nullable().optional(),
    firstRegistrationDate: z.string().datetime().nullable().optional(),
    transferable: z.string().nullable().optional(),
    color: z.string().nullable().optional(),
    titleReservation: z.boolean().nullable().optional(),
    hasDebt: z.boolean().nullable().optional(),
});

export const ForeignDetail = z.object({
    residenceStartDate: z.string().datetime().nullable().optional(),
    residenceCardExpirationDate: z.string().datetime().nullable().optional(),
    socialSecurityRegistrationDate: z.string().datetime().nullable().optional(),
    numberOfJobs: z.number().nullable().optional(),
});
export const GetMktDetailsResponse = z.object({
    utmSource: z.string().optional(),
    utmMedium: z.string().optional(),
    utmCampaign: z.string().optional(),
    productSource: z.string().nullable(),
    originSource: z.string().nullable(),
});

export const MktDetail = GetMktDetailsResponse;

export const PersonalDetail = z.object({
    email: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
    secondPhone: z.string().nullable().optional(),
    identificationNumber: IdentificationNumber.nullable().optional(),
    gender: Gender.nullable().optional(),
    name: z.string().nullable().optional(),
    lastName: z.string().nullable().optional(),
    secondLastName: z.string().nullable().optional(),
    dateOfBirth: z.string().datetime().nullable().optional(),
    maritalStatus: MaritalStatus.nullable().optional(),
    nationality: z.string().nullable().optional(),
    numberOfChildren: z.number().nullable().optional(),
    rentOrMortgageAmount: z.number().nullable().optional(),
    countryOfBirth: z.string().nullable().optional(),
    loanPurpose: LoanPurpose.nullable().optional(),
    legalNature: LegalNature.nullable(),
    extId: z.string().nullable(),
});
export const GetDealPersonalDetailsResponse = PersonalDetail.extend({
    clientUuid: z.string(),
});
export const PutDealPersonalDetailsRequest = PersonalDetail.extend({
    dateOfBirth: z.date().nullable().optional(),
    identificationNumber: z
        .object({
            number: z.string().optional(),
        })
        .nullable()
        .optional(),
});
export const GetDealEmploymentResponse = Employment;
export const PutDealEmploymentRequest = Employment.extend({
    seniorityDate: z.date().nullable().optional(),
});
export const GetDealBankDetailResponse = BankDetail.extend({
    isAccountAtLeast6MonthsOlder: z.boolean().nullable().optional(),
    isBalanceAtLeast200: z.boolean().nullable().optional(),
});
export const PutDealBankDetailRequest = BankDetail;

export const GetDealCoownerDetailResponse = CoownerDetail;
export const PutDealCoownerDetailRequest = CoownerDetail;

export const GetDealAddressesResponse = Addresses;
export const PutDealAddressesRequest = Addresses;

export const GetDealForeignDetailResponse = ForeignDetail;
export const PutDealForeignDetailResponse = ForeignDetail;

export const GetDealVehicleReponse = Vehicle;

export const PutDealVehicleRequest = z.object({
    transferable: z.string().nullable().optional(),
    color: z.string().nullable().optional(),
    brand: z.string().nullable().optional(),
    model: z.string().nullable().optional(),
    version: z.string().nullable().optional(),
    yearRegistration: z.number().nullable().optional(),
    gearboxType: GearboxType.nullable().optional(),
    combustibleType: CombustibleType.nullable().optional(),
    lastOdometerReadingValue: z.number().nullable().optional(),
    firstRegistrationDate: z.string().datetime().nullable().optional(),
    vin: z.string().nullable().optional(),
    titleReservation: z.boolean().nullable().optional(),
    hasDebt: z.boolean().nullable().optional(),
});

export const PutDealVehicleResponse = Vehicle;

export const DealInfoDataView = z.object({
    personalDetail: PersonalDetail,
    employment: Employment,
    addresses: Addresses,
    vehicle: GetDealVehicleReponse,
    foreignDetail: ForeignDetail,
    mktDetail: MktDetail,
    bankDetail: BankDetail,
    coownerDetail: CoownerDetail,
});

export const GetDealCatalogueVehicle = z.object({
    adPrice: Measurement.nullable().optional(),
    purchasePrice: Measurement.nullable().optional(),
    preparationExpenses: Measurement.nullable().optional(),
    premiumWarranty: Measurement.nullable().optional(),
    deliveryExpenses: Measurement.nullable().optional(),
    brandWarrantyExpiredAt: z.string().datetime().nullable(),
});

export const DealClientAssignment = z.object({
    clientUuid: z.string(),
});

export type PersonalDetail = z.infer<typeof PersonalDetail>;
export type Address = z.infer<typeof Address>;
export type Addresses = z.infer<typeof Addresses>;
export type Employment = z.infer<typeof Employment>;
export type Vehicle = z.infer<typeof Vehicle>;
export type ForeignDetail = z.infer<typeof ForeignDetail>;
export type MktDetail = z.infer<typeof MktDetail>;
export type BankDetail = z.infer<typeof BankDetail>;
export type CoownerDetail = z.infer<typeof CoownerDetail>;
export type ContractInfo = z.infer<typeof ContractInfo>;
export type GetMktDetailsResponse = z.infer<typeof GetMktDetailsResponse>;
export type GetDealPersonalDetailsResponse = z.infer<typeof GetDealPersonalDetailsResponse>;
export type PutDealPersonalDetailsRequest = z.infer<typeof PutDealPersonalDetailsRequest>;
export type GetDealVehicleReponse = z.infer<typeof GetDealVehicleReponse>;
export type PutDealVehicleRequest = z.infer<typeof PutDealVehicleRequest>;
export type PutDealVehicleResponse = z.infer<typeof PutDealVehicleResponse>;
export type GetDealEmploymentResponse = z.infer<typeof GetDealEmploymentResponse>;
export type PutDealEmploymentRequest = z.infer<typeof PutDealEmploymentRequest>;
export type GetDealBankDetailResponse = z.infer<typeof GetDealBankDetailResponse>;
export type PutDealBankDetailRequest = z.infer<typeof PutDealBankDetailRequest>;
export type GetDealCoownerDetailResponse = z.infer<typeof GetDealCoownerDetailResponse>;
export type PutDealCoownerDetailRequest = z.infer<typeof PutDealCoownerDetailRequest>;
export type GetDealAddressesResponse = z.infer<typeof GetDealAddressesResponse>;
export type PutDealAddressesRequest = z.infer<typeof PutDealAddressesRequest>;
export type GetDealForeignDetailResponse = z.infer<typeof GetDealForeignDetailResponse>;
export type PutDealForeignDetailResponse = z.infer<typeof PutDealForeignDetailResponse>;
export type DealInfoDataView = z.infer<typeof DealInfoDataView>;
export type GetDealCatalogueVehicle = z.infer<typeof GetDealCatalogueVehicle>;
export type DealClientAssignment = z.infer<typeof DealClientAssignment>;
